import {
  ApolloCache,
  ApolloError,
  DocumentNode,
  FetchResult,
  gql,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationResult,
  TypedDocumentNode,
  useApolloClient,
  useMutation,
} from "@apollo/client";
import { GraphQLError } from "graphql";
import isObject from "lodash/isObject";
import { useState } from "react";
import { Connector, UserRejectedRequestError } from "wagmi";

import { Context } from "~web/apiClient/client";
import { FetchAuthMessageQuery } from "~web/generated/graphql";

const isWalletConnectError = (e: unknown): e is string => typeof e === "string";
const isMetaMaskError = (e: unknown): e is { code: number } =>
  isObject(e) && e.hasOwnProperty("code");

const isUserRejectedError = (e: unknown) =>
  (isWalletConnectError(e) && e === "Failed or Rejected Request") ||
  (isMetaMaskError(e) && e.code === 4001) ||
  e instanceof UserRejectedRequestError;

const FETCH_AUTH_MESSAGE_QUERY = gql`
  query FetchAuthMessage {
    getAuthMessage
  }
`;

/**
 * Wrapper hook for mutations that require wallet authentication.
 *
 * This hook wraps Apollo's normal `useMutation` hook and provides a near 1-1
 * replacement for it, whilst also handling getting the user's signature via their
 * connected wallet.
 */
export const useWalletAuthMutation = <
  TData,
  TVariables = Record<string, unknown>,
  TCache extends ApolloCache<unknown> = ApolloCache<unknown>
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, Context>
): [
  (
    connector: Connector,
    options?: MutationFunctionOptions<TData, TVariables, Context, TCache>
  ) => Promise<FetchResult<TData>>,
  MutationResult<TData>
] => {
  const [_mutate, { error: _error, ...rest }] = useMutation<
    TData,
    TVariables,
    Context,
    TCache
  >(mutation, {
    variables: options?.variables,
    context: { signedMessage: "" },
  });
  const client = useApolloClient();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | ApolloError>();

  const mutate = async (
    connector: Connector,
    options?: MutationFunctionOptions<TData, TVariables, Context, TCache>
  ) => {
    setLoading(true);
    setError(undefined);
    let address;
    let signedMessage;

    // 0. Connect a wallet if it's not already, and get the address
    try {
      await connector.connect();
      address = await connector.getAccount();
    } catch (e) {
      setLoading(false);
      if (isUserRejectedError(e)) {
        return { data: null };
      }

      return {
        data: null,
        errors: [new GraphQLError(String(e))],
      };
    }

    // 1. Get the message to sign
    const gqlResponse = await client.query<FetchAuthMessageQuery>({
      query: FETCH_AUTH_MESSAGE_QUERY,
      context: { address },
    });
    if (gqlResponse.errors || !gqlResponse.data.getAuthMessage) {
      console.error(
        "GraphQL errors whilst submitting authed query:",
        gqlResponse.errors
      );
      setLoading(false);
      return {
        data: null,
        errors: gqlResponse.errors,
      };
    }

    // 2. Sign the message
    try {
      const signer = await connector.getSigner();
      signedMessage = await signer.signMessage(gqlResponse.data.getAuthMessage);
    } catch (e) {
      setLoading(false);

      if (isUserRejectedError(e)) {
        return {
          data: null,
        };
      }
      return {
        data: null,
        errors: [new GraphQLError(String(e))],
      };
    }

    // 3. Send the mutation
    return _mutate({
      ...options,
      context: { signedMessage, address },
    }).then((x) => {
      setLoading(false);
      return x;
    });
  };

  return [mutate, { ...rest, loading, error: error || _error }];
};
