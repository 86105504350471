// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://14e8c14de1434cd68782972cbc32fa8e@o1159311.ingest.sentry.io/6243029",
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === "production",
});
