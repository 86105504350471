import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { useMemo } from "react";
import pickBy from "lodash/pickBy";
import { onError } from "@apollo/client/link/error";
import { SentryLink } from "apollo-link-sentry";
import * as Sentry from "@sentry/browser";

let apolloClient: ApolloClient<NormalizedCacheObject>;

export type Cookies = Record<string, string | undefined>;

export interface Context {
  address?: string;
  signedMessage?: string;
}

const authLink = () =>
  new ApolloLink((operation, forward) => {
    const { address, signedMessage } = operation.getContext() as Context;

    const headers = {
      "x-auth-address": address || undefined,
      "x-auth-signed-message": signedMessage || undefined,
    };

    operation.setContext({
      // Remove undef values from the headers as weirdly these get sent as "undefined"
      headers: pickBy(headers),
    });

    return forward(operation);
  });

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => Sentry.captureMessage(message));
  }
});

function createApolloClient() {
  return new ApolloClient({
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
      query: {
        fetchPolicy: "network-only",
        errorPolicy: "all",
      },
    },
    link: ApolloLink.from([
      authLink(),
      new SentryLink(),
      errorLink,
      new HttpLink({
        credentials: "include",
        uri:
          process.env.NODE_ENV === "production"
            ? process.env.API_URL
            : "http://localhost:4000/graphql",
      }),
    ]),
  });
}

type InitialState = NormalizedCacheObject | null;

export function initializeApollo(initialState: InitialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useApollo(initialState: InitialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
